import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, empty } from "rxjs";
import { finalize, timeout, retry, catchError } from "rxjs/operators";  
import { LoadingHelper } from '../helpers/loading.helper'; 
import { LSConstants } from "../models/app.constants";
import { CacheService } from "../services/cache.service";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
    constructor(public loadingHelper: LoadingHelper,
      private cacheService: CacheService) { }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // append language to request header
        request = request.clone({
          headers: request.headers.append('language', this.cacheService.currentLanguage)
        });

        //this.loadingHelper.show(); 
        return next.handle(request)
        .pipe(
            //retry(3), // retry a failed request up to 3 times
            catchError(this.handleError),
            finalize(() => this.loadingHelper.hide())
        );
    }

    private handleError(error: HttpErrorResponse){
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) { 
        // A client-side or network error occurred.
        errorMessage = 'An error occurred:' + error.error.message;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        errorMessage = `Backend returned code: ${error.status}\nbody was: ${error.error}`;
      }

      // In case of Unhautorized
      if(error.status == 401) {
        // localStorage.removeItem(LSConstants.USERID);
        // localStorage.removeItem(LSConstants.USERNAME);
        // localStorage.removeItem(LSConstants.PASSWORD);
        // localStorage.removeItem(LSConstants.TOKEN);
      }

      console.error(error);
      return throwError(errorMessage);
    }
}