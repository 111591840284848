import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor() { }

  setBusinessTheme(color: string) {
    switch (color) {
      // Default
      case "#ffb050":
        // pre-defined on ../src/assets/themes.css
        break;
      // Red
      case "#FC8370":
        document.documentElement.style.setProperty('--business-color', "#ff9c8d");
        document.documentElement.style.setProperty('--business-color2', "#ff7a60");
        document.documentElement.style.setProperty('--business-color3', "#582119");
        document.documentElement.style.setProperty('--business-color4', "#582119");
        document.documentElement.style.setProperty('--business-colorWhite', color + '5e'); // transparency
        break;
      // Yellow
      case "#FCD277":
        document.documentElement.style.setProperty('--business-color', "#ffd478");
        document.documentElement.style.setProperty('--business-color2', "#FECD57");
        document.documentElement.style.setProperty('--business-color3', "#654d1e");
        document.documentElement.style.setProperty('--business-color4', "#654d1e");
        document.documentElement.style.setProperty('--business-colorWhite', color + '5e'); // transparency
        break;
      // Green
      case "#B4E080":
        document.documentElement.style.setProperty('--business-color', "#bce48d");
        document.documentElement.style.setProperty('--business-color2', "#9ED36A");
        document.documentElement.style.setProperty('--business-color3', "#3f5627");
        document.documentElement.style.setProperty('--business-color4', "#3f5627");
        document.documentElement.style.setProperty('--business-colorWhite', color + '5e'); // transparency
        break;
      // Mint
      case "#62DDBD":
        document.documentElement.style.setProperty('--business-color', "#82e9cd");
        document.documentElement.style.setProperty('--business-color2', "#3dc0a0");
        document.documentElement.style.setProperty('--business-color3', "#1b5648");
        document.documentElement.style.setProperty('--business-color4', "#1b5648");
        document.documentElement.style.setProperty('--business-colorWhite', color + '5e'); // transparency
        break;
      // Aqua
      case "#66d4f1":
        document.documentElement.style.setProperty('--business-color', "#83dff7");
        document.documentElement.style.setProperty('--business-color2', "#52b5d8");
        document.documentElement.style.setProperty('--business-color3', "#19485a");
        document.documentElement.style.setProperty('--business-color4', "#19485a");
        document.documentElement.style.setProperty('--business-colorWhite', color + '5e'); // transparency
        break;
      // Violet
      case "#B3A5EF":
        document.documentElement.style.setProperty('--business-color', "#cec5f4");
        document.documentElement.style.setProperty('--business-color2', "#a38dd5");
        document.documentElement.style.setProperty('--business-color3', "#332458");
        document.documentElement.style.setProperty('--business-color4', "#332458");
        document.documentElement.style.setProperty('--business-colorWhite', color + '5e'); // transparency
        break;
      // Pink
      case "#F299CE":
        document.documentElement.style.setProperty('--business-color', "#f4c0df");
        document.documentElement.style.setProperty('--business-color2', "#EB87BF");
        document.documentElement.style.setProperty('--business-color3', "#521d3c");
        document.documentElement.style.setProperty('--business-color4', "#521d3c");
        document.documentElement.style.setProperty('--business-colorWhite', color + '5e'); // transparency
        break;
      // Skin Tone
      case "#f4d0b5":
        document.documentElement.style.setProperty('--business-color', color);
        document.documentElement.style.setProperty('--business-color2', "#F0C8A5");
        document.documentElement.style.setProperty('--business-color3', "#54463c");
        document.documentElement.style.setProperty('--business-color4', "#54463c");
        document.documentElement.style.setProperty('--business-colorWhite', color + '5e'); // transparency
        break;
      // Black
      case "#1c1c1c":
        document.documentElement.style.setProperty('--business-color', "#2d2d2d");
        document.documentElement.style.setProperty('--business-color2', "#F0C8A5");
        document.documentElement.style.setProperty('--business-color3', "#efefef");
        document.documentElement.style.setProperty('--business-color4', "#1f1f1f");
        document.documentElement.style.setProperty('--business-colorWhite', "#a7a7a75e"); // transparency
        break;
    }
  }
}