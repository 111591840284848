import { Injectable } from '@angular/core';
import { AppConfigModel } from '../models/config.model';
import { CacheService } from './cache.service';

export function AppInit(initService: InitService) { return () => { initService.init(); } }

@Injectable({
    providedIn: 'root'
})
export class InitService {
    config: AppConfigModel;
    appTranslation: any;

    constructor(private cacheService: CacheService) { }

    async init() {
        new Promise((resolve) => {
            this.config = this.getFile('./assets/config/config.json') as AppConfigModel;

            let language = localStorage.getItem("lang");
            if (!language) {
                localStorage.setItem("lang", "en");
                language = "en";
            }

            this.translate(language);
            resolve(true);
        });
    }

    getFile(url) {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open('GET', url, false);
        xmlHttp.send(null);
        return JSON.parse(xmlHttp.responseText);
    }

    translate(language) {
        this.cacheService.currentLanguage = language;
        this.appTranslation = this.getFile(`./assets/config/translation_${language}.json`);
        localStorage.setItem("lang", language);

        // translate program name
        if (this.cacheService.currentRoute) {
            if (this.cacheService.currentRoute.endsWith("/location"))
                this.cacheService.currentProgramName = this.cacheService.currentProgramName; //business name
            else if ((this.cacheService.currentRoute.endsWith("/services")))
                this.cacheService.currentProgramName = this.appTranslation.BOOKING.SERVICES.PROGRAM_NAME;
            else if ((this.cacheService.currentRoute.endsWith("/staff")))
                this.cacheService.currentProgramName = this.appTranslation.BOOKING.STAFF.PROGRAM_NAME;
            else if ((this.cacheService.currentRoute.endsWith("/time")))
                this.cacheService.currentProgramName = this.appTranslation.BOOKING.TIME.PROGRAM_NAME;
            else if ((this.cacheService.currentRoute.endsWith("/customer")))
                this.cacheService.currentProgramName = this.appTranslation.BOOKING.CUSTOMER.PROGRAM_NAME;
            else if ((this.cacheService.currentRoute.endsWith("/resume")))
                this.cacheService.currentProgramName = this.appTranslation.BOOKING.RESUME.PROGRAM_NAME;
        }
    }
}