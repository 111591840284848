import { Injectable } from '@angular/core'; 
import { CacheService } from './cache.service'; 
import { LSConstants } from '../models/app.constants';

export function AppStartup(dataService: DataService) { return () => dataService.loadInitialData(); }

@Injectable({
  providedIn: 'root'
})
export class DataService { 
  constructor(private cacheService: CacheService)
    { }

  /* este metodo corre no F5 */
  async loadInitialData() {   
    //...
  }
 
  /* este metodo corre apenas no login efetuado */
  async loadLoginData(){

  }
}