import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { LSConstants } from '../models/app.constants';
import { ApiResponseModel } from '../models/api-response.model';
import { CacheService } from '../services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class ApiHelper {

  constructor() { }
 
  static getHeaders() { 
    let date = new Date();
    let dateFormat_ddMMyyyy = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '' +
      ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '' +
      date.getFullYear();

    let secretKey = dateFormat_ddMMyyyy + "jzae>s%?QJ5HG>Db:R:@za;#Dww?3F*k8;'&)[;6";  

    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('SecretKey', btoa(secretKey)) //toBase64String
  }

  /** @param exceptions List of parameters to not include, separated by comma */
  static getHttpParams(model: any, exceptions: string = "") {
    let httpParams = new HttpParams();
    Object.keys(model).forEach(function (key) {
      if (exceptions.split(',').filter(e => e == key).length == 0)
        httpParams = httpParams.append(key, model[key]);
    });

    return httpParams;
  }

  static checkResponse(response){
    return response === ApiResponseModel;
  }
} 