 import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInit, InitService } from './services/init.service';
import { AppStartup, DataService } from './services/data.service';
import { AppInterceptor } from './interceptors/app.interceptor';
import { BookingLayoutModule } from './layouts/booking-layout/booking-layout.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BookingLayoutModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInit,
      deps: [InitService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppStartup,
      deps: [DataService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor, 
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
