import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationHelper } from './helpers/translation.helper';
import { CacheService } from './services/cache.service';
import { InitService } from './services/init.service';
import { PlatformService } from './services/platform.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  translation: any;
  
  constructor(private t: TranslationHelper,
    public cacheService: CacheService,
    private initService: InitService,
    private platformService: PlatformService,
    private router: Router) {
      this.translation = t.getTranslation("FOOTER");
    }

  changeLanguage() {
    const nextLanguageIndex = this.initService.config.appLanguages
      .indexOf(this.cacheService.currentLanguage) + 1;

    if(nextLanguageIndex && nextLanguageIndex == this.initService.config.appLanguages.length)
      this.initService.translate(this.initService.config.appLanguages[0]);
    else
      this.initService.translate(this.initService.config.appLanguages[nextLanguageIndex]);
    
    const routeEmitter = this.cacheService.currentRoute.split('/').pop();
    // por segurança por enquanto funciona apenas com estas duas rotas
    if(routeEmitter == 'services' || routeEmitter == "time")
      this.platformService.changeLanguageEventEmitter.emit(routeEmitter);
  }

  linkClick(div) {
    var elmnt = document.getElementById(div);
    window.scroll(0,elmnt.offsetTop - 80);
  }

  openRouteInNewWindow(route) {
    // Converts the route into a string that can be used 
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route])
    );
  
    window.open(url, '_blank');
  }

  getLanguageImage() {
    switch (this.cacheService.currentLanguage) {
      case "pt":
        return "../../../assets/images/PT.svg";
      case "en":
        return "../../../assets/images/EN.svg";
      case "es":
        return "../../../assets/images/ES.svg";
    }
  }
}
