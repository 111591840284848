import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingLayoutRoutingModule } from './booking-layout-routing.module';
import { BookingLayoutComponent } from './booking-layout.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [BookingLayoutComponent],
  imports: [
    CommonModule,
    BookingLayoutRoutingModule,
    FormsModule
  ]
})
export class BookingLayoutModule { }
