import { Injectable } from '@angular/core';
import { TranslationModel } from '../models/translation.model';
import { InitService } from '../services/init.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationHelper {

  constructor(private initService: InitService) { }

  getTranslation(keys:string):string {
    // eg. keys -> clients.TAB_ALL
    let obj = this.initService.appTranslation;
    let finded = true;

    keys.split('.').forEach(key => {
      const value = obj[key];
      if(value)
        obj = value;
      else
        finded = false;
    }); 

    return finded ? obj : "NoTranslation";
  }

  getTranslatedWeekDays(lang:string){
    switch (lang.toUpperCase()) {
      case "PT":
        return ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"];
        //return ["S", "T", "Q", "Q", "S", "S", "D"];
      case "EN":
        return ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
      case "ES":
        return ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];
    }
  }

  getTranslatedInterval(month, year, language):string{
    switch (true) {
      case month == '1'  && language == 'pt': return `Janeiro ${year} `;
      case month == '2'  && language == 'pt': return `Fevereiro ${year} `;
      case month == '3'  && language == 'pt': return `Março ${year} `;
      case month == '4'  && language == 'pt': return `Abril ${year} `;
      case month == '5'  && language == 'pt': return `Maio ${year} `;
      case month == '6'  && language == 'pt': return `Junho ${year} `;
      case month == '7'  && language == 'pt': return `Julho ${year} `;
      case month == '8'  && language == 'pt': return `Agosto ${year} `;
      case month == '9'  && language == 'pt': return `Setembro ${year} `;
      case month == '10' && language == 'pt': return `Outubro ${year} `;
      case month == '11' && language == 'pt': return `Novembro ${year} `;
      case month == '12' && language == 'pt': return `Dezembro ${year} `;

      case month == '1'  && language == 'en': return `January ${year}`;
      case month == '2'  && language == 'en': return `February ${year}`;
      case month == '3'  && language == 'en': return `March ${year}`;
      case month == '4'  && language == 'en': return `April ${year}`;
      case month == '5'  && language == 'en': return `May ${year}`;
      case month == '6'  && language == 'en': return `June ${year}`;
      case month == '7'  && language == 'en': return `July ${year}`;
      case month == '8'  && language == 'en': return `August ${year}`;
      case month == '9'  && language == 'en': return `September ${year}`;
      case month == '10' && language == 'en': return `October ${year}`;
      case month == '11' && language == 'en': return `November ${year}`;
      case month == '12' && language == 'en': return `December ${year}`;

      case month == '1'  && language == 'es': return `January ${year}`;
      case month == '2'  && language == 'es': return `February ${year}`;
      case month == '3'  && language == 'es': return `March ${year}`;
      case month == '4'  && language == 'es': return `April ${year}`;
      case month == '5'  && language == 'es': return `May ${year}`;
      case month == '6'  && language == 'es': return `June ${year}`;
      case month == '7'  && language == 'es': return `July ${year}`;
      case month == '8'  && language == 'es': return `August ${year}`;
      case month == '9'  && language == 'es': return `September ${year}`;
      case month == '10' && language == 'es': return `October ${year}`;
      case month == '11' && language == 'es': return `November ${year}`;
      case month == '12' && language == 'es': return `December ${year}`;
    }
  }

  fillTranslations(translations: TranslationModel[]) {
    let translation = translations.find(t=>t.Language == 'PT');
    if(!translation)
      translations.push(this.fillTranslation("PT"));

    translation = translations.find(t=>t.Language == 'EN');
    if(!translation)
      translations.push(this.fillTranslation("EN"));

    translation = translations.find(t=>t.Language == 'ES');
    if(!translation)
      translations.push(this.fillTranslation("ES"));

    return translations;
  }

  fillTranslation(language) {
    return {
      Id: 0,
      ReferenceId: 0,
      TableName: "",
      Translation1: "",
      Translation2: "",
      Translation3: "",
      Translation4: "",
      Translation5: "",
      Language: language
    } as TranslationModel
  }

}