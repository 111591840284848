import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiHelper } from 'src/app/helpers/api.helper';
import { InitService } from './init.service';
import { ApiResponseModel } from '../models/api-response.model';
import { CacheService } from './cache.service';
import { BookingModel, GetBusinessServicesRequestModel, GetBusinessStaffRequestModel, GetTimeSlotsRequestModel, RegisterRequestModel } from '../models/platform.model';

@Injectable({
  providedIn: 'root'
})
export class PlatformService { 
  
  language:string;

  confirmServicesEventEmitter = new EventEmitter<boolean>();
  changeLanguageEventEmitter = new EventEmitter<string>(); 

  constructor(private initService: InitService,
    private cacheService: CacheService,
    private http: HttpClient) {
      this.language = this.cacheService.currentLanguage;
    }

  getBusinessByName(name) {
    return this.http.get<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/platform/business/' + name, {
      headers: ApiHelper.getHeaders()
    });
  }

  getBusinessLocations(businessId) {
    return this.http.get<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/platform/business/locations', {
      headers: ApiHelper.getHeaders(),
      params: ApiHelper.getHttpParams({businessId: businessId})
    });
  }

  getBusinessServices(request:GetBusinessServicesRequestModel) { 
    return this.http.get<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/platform/business/services', {
      headers: ApiHelper.getHeaders(),
      params: ApiHelper.getHttpParams(request)
    });
  }

  getBusinessStaff(request:GetBusinessStaffRequestModel) {
    return this.http.post<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/platform/business/staff', request, {
      headers: ApiHelper.getHeaders()
    });
  }

  getTimeSlots(request:GetTimeSlotsRequestModel) {
    return this.http.post<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/platform/business/timeslots', request, {
      headers: ApiHelper.getHeaders()
    });
  }

  getBusinessExtraFields(businessId) {
    return this.http.get<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/platform/business/extrafields', {
      headers: ApiHelper.getHeaders(),
      params: ApiHelper.getHttpParams({businessId: businessId})
    });
  }

  booking(booking:BookingModel) {
    return this.http.post<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/platform/business/booking', booking, {
      headers: ApiHelper.getHeaders()
    });
  }

  getBusinessesCategories() {
    return this.http.get<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/businesses/categories/' + this.language, {
      headers: ApiHelper.getHeaders()
    });
  }

  register(request: RegisterRequestModel) {
    return this.http.post<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/platform/register', request, {
      headers: ApiHelper.getHeaders()
    });
  }

  checkBusinessName(name) {
    return this.http.post<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/businesses/namecheck/' + name, {
      headers: ApiHelper.getHeaders()
    });
  }

  checkUserEmail(email) {
    return this.http.post<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/users/emailcheck/' + email, {
      headers: ApiHelper.getHeaders()
    });
  }

  checkTimeSlot(id) {
    return this.http.post<ApiResponseModel>(this.initService.config.apiEndpoint + 'api/platform/timeslotcheck/' + id, {
      headers: ApiHelper.getHeaders()
    });
  }
}