import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 

const routes: Routes = [
  { path: 'location', loadChildren: () => import('../../pages/booking/1_locations/locations.module').then(m => m.LocationsModule) },
  { path: 'services', loadChildren: () => import('../../pages/booking/2_services/services.module').then(m => m.ServicesModule) },
  { path: 'staff', loadChildren: () => import('../../pages/booking/3_staff/staff.module').then(m => m.StaffModule ) },
  { path: 'time', loadChildren: () => import('../../pages/booking/4_time/time.module').then(m => m.TimeModule) },
  { path: 'customer', loadChildren: () => import('../../pages/booking/5_customer/customer.module').then(m => m.CustomerModule) },
  { path: 'resume', loadChildren: () => import('../../pages/booking/6_resume/resume.module').then(m => m.ResumeModule) }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingLayoutRoutingModule { }
