import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PlatformService } from 'src/app/services/platform.service';
import { CacheService } from 'src/app/services/cache.service';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslationHelper } from 'src/app/helpers/translation.helper';
import { InitService } from 'src/app/services/init.service';
import { GetBusinessServicesRequestModel, GetBusinessStaffRequestModel } from 'src/app/models/platform.model';

@Component({
  selector: 'app-booking-layout',
  templateUrl: './booking-layout.component.html',
  styleUrls: ['./booking-layout.component.scss']
})
export class BookingLayoutComponent implements OnInit {
  businessName: string;
  currentRoute = "";

  constructor(private t: TranslationHelper,
    public cacheService: CacheService,
    private platformService: PlatformService,
    private themeService: ThemeService,
    private router: Router,
    private route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.businessName = params.get('businessName');
      this.cacheService.businessNameBookingUrl = this.businessName + '/book';
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
        this.cacheService.currentRoute = this.currentRoute;
      }
    })

  }

  async ngOnInit() { 
    this.cacheService.business = await (await this.platformService.getBusinessByName(this.businessName).toPromise()).Result;
    await this.themeService.setBusinessTheme(this.cacheService.business.Color);
    this.cacheService.appVisible = true;
    this.goToInitialRoute();
  }

  async goToInitialRoute() {

    let route = "location";
    const locations = await (await this.platformService.getBusinessLocations(this.cacheService.business.Id).toPromise()).Result;
    if (locations.length == 1) {
      this.cacheService.selectedLocation = locations[0].Id;
      this.cacheService.navigatedBack = false; // ?

      route = "services";
      const request: GetBusinessServicesRequestModel = {
        BusinessId: this.cacheService.business.Id,
        BusinessLocationId: this.cacheService.selectedLocation,
        Language: this.cacheService.currentLanguage
      };
      const servicesGroup = await (await this.platformService.getBusinessServices(request).toPromise()).Result;
      if ((servicesGroup.length == 1) && (servicesGroup[0].Services.length == 1)) {
        const singleService = servicesGroup[0].Services[0];
        this.cacheService.selectedServices.push(singleService);
        this.cacheService.selectedServicesDuration = (this.cacheService.selectedServicesDuration + singleService.Duration);

        route = "staff";
        const request:GetBusinessStaffRequestModel = {
          BusinessId: this.cacheService.business.Id,
          Language: this.cacheService.currentLanguage,
          LocationId: this.cacheService.selectedLocation,
          Services: this.cacheService.selectedServices.map(s=>s.Id)
        };
        const staffMembers = await (await this.platformService.getBusinessStaff(request).toPromise()).Result;
        if(staffMembers.length == 1) {
          this.cacheService.selectedStaff = staffMembers[0].Id;
          this.cacheService.navigatedBack = false; // ?
          route = "time";
        }
      }
    }

    this.cacheService.firstStepRoute = route;

    // eg. canslot.com/lfw/book
    if(this.currentRoute.endsWith("/book"))
      this.router.navigate([route], {relativeTo: this.route});
    else {
      // F5 a pagina!! eg. canslot.com/lfw/book/*
      if(this.currentRoute.includes("book")) {
        this.router.navigate([`${this.businessName}/book/location`]);
      } else {
        // eg. canslot.com/lfw
        this.router.navigate([`book/${route}`], {relativeTo: this.route});
      }
    }
  }

  back() {
    const splitted = this.currentRoute.split('/');
    const routeEnding = splitted[splitted.length - 1];
    switch (routeEnding) {
      case 'services':
        this.router.navigate([`${this.cacheService.businessNameBookingUrl}/location`]);
        break;
      case 'staff':
        this.router.navigate([`${this.cacheService.businessNameBookingUrl}/services`]);
        break;
      case 'time':
        this.router.navigate([`${this.cacheService.businessNameBookingUrl}/staff`]);
        break;
      case 'customer':
        this.router.navigate([`${this.cacheService.businessNameBookingUrl}/time`]);
        break;
      case 'resume':
        this.router.navigate([`${this.cacheService.businessNameBookingUrl}/customer`]);
        break;
    }
    this.cacheService.navigatedBack = true;
  }

  confirmServices() {
    this.platformService.confirmServicesEventEmitter.emit(true);
  }

  confirmServicesVisible() {
    const splitted = this.currentRoute.split('/');
    const routeEnding = splitted[splitted.length - 1];
    if(routeEnding == "services" && this.cacheService.selectedServices.length > 0)
      return true;
    else
      return false;
  }

  backVisible() {
    if(!this.cacheService.currentProgramName)
      return false;

    return (this.cacheService.currentProgramName)
      && (this.cacheService.showBackButton)
      && (!this.cacheService.currentRoute.endsWith(this.cacheService.firstStepRoute));
  }

  goToPageWithDefaultValues(page) {
    this.cacheService.selectedLocation = 1; //Lisboa
    this.cacheService.selectedServices = [{
      Id: 5, //BigTattoo
      BusinessId: 0,
      BusinessServiceCategoryId: 0,
      Price: 0,
      Duration: 0,
      IsPopular: false,
      IsNew: false,
      Active: false,
      DailyLimit: 999,
      Translations: null,
      Name: '',
      Description: ''
    }];
    this.cacheService.selectedStaff = 10; //Luis
    this.router.navigate([`${this.cacheService.businessNameBookingUrl}/${page}`]);
  }

  getLanguageImage() {
    switch (this.cacheService.currentLanguage) {
      case "pt":
        return "../../../assets/images/PT.svg";
      case "en":
        return "../../../assets/images/EN.svg";
      case "es":
        return "../../../assets/images/ES.svg";
    }
  }

}
