import { Injectable } from '@angular/core';
import { BusinessCategoryModel, PlatformBusinessModel, PlatformBusinessServiceModel, PlatformBusinessStaffModel, PlatformTimeSlotModel } from '../models/platform.model';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  appVisible:Boolean = false;
  currentLanguage: string = "pt";
  currentProgramName: string;
  currentRoute: string;
  businessNameBookingUrl: string;
  business: PlatformBusinessModel;
  businessesCategories: BusinessCategoryModel[];

  // booking steps
  firstStepRoute: string;
  navigatedBack:boolean = false;
  showBackButton:boolean = false; 
  selectedLocation: number;
  selectedServices: PlatformBusinessServiceModel[] = [];
  selectedServicesDuration: number = 0;
  selectedStaff: number;
  staff: PlatformBusinessStaffModel[] = [];
  selectedTimeSlot: PlatformTimeSlotModel;
  bookingStatus: number;

  constructor() {} 

  isMurillosBarber() {
    return this.business.Name.toUpperCase().startsWith("MURILL");
  }
  
}