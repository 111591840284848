import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingLayoutComponent } from './layouts/booking-layout/booking-layout.component';

const routes: Routes = [
  {
    path: ':businessName',
    redirectTo: ':businessName/book'
  },
  {
    path: ':businessName/book',
    component: BookingLayoutComponent,
    loadChildren: () => import('./layouts/booking-layout/booking-layout.module').then(m => m.BookingLayoutModule)
  },
  // MARKETING OFF
  // {
  //   path: '',
  //   loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  // },
  // {
  //   path: 'get/started',
  //   loadChildren: () => import('./pages/get-started/get-started.module').then(m => m.GetStartedModule)
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
